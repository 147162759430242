<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t("Filters") }}
      </h5>
      <b-button @click="clearFilters" variant="secondary" size="sm">{{ $t("Limpar Filtros") }}</b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>{{ $t("Data inicial") }}</label>
          <flat-pickr v-model="startDate" class="form-control" name="date" :placeholder="$t('Data inicial')" />
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2">
          <label>{{ $t("Data final") }}</label>
          <flat-pickr v-model="endDate" class="form-control" name="date" :placeholder="$t('Data final')" />
        </b-col>
        <b-col cols="12" md="6" class="mb-md-0 mb-2" v-if="productOptions">
          <label>{{ $t("Produto") }}</label>
          <v-select 
            v-model="selectedProduct" 
            :options="productOptions" 
            :placeholder="$t('Selecione um produto')" 
          />
        </b-col>
      </b-row>      
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr
  },
  props: {
    productOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      selectedProduct: null,  // Adicionei esta linha
    };
  },
  methods: {
    clearFilters() {
      this.startDate = null;
      this.endDate = null;
      this.selectedProduct = null;  // Adicionei esta linha
    },
  },
  watch: {
    startDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.$emit('updateStartDate', newDate);
      }
    },
    endDate(newDate, oldDate) {
      if (newDate !== oldDate) {
        this.$emit('updateEndDate', newDate);
      }
    },
    selectedProduct(newProduct, oldProduct) {  // Adicionei este watcher
      if (newProduct !== oldProduct) {
        this.$emit('updateSelectedProduct', newProduct);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
